
	import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
	import Popup from '@pixcap/ui-library/components/Popup.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconArrowDown from '@pixcap/ui-library/components/Icons/IconArrowDown.vue';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';

	@Component({
		name: 'HeaderItem',
		components: {
			IntersectVideoAutoplay,
			IconArrowDown,
			Popup,
			BodyText,
			CaptionText,
		},
	})
	export default class HeaderItem extends Vue {
		@Prop() readonly item: any;

		@InjectReactive() isDesktopScreen: boolean;

		get hasChildren() {
			return !!this.item.childrens?.length;
		}

		handleItemClick(handler) {
			handler();
		}
	}
